<template>
  <div>
    <div class="title flexB" v-if="gnbVisible">
      <h1>{{ $t("top-select-total-view-vod") }}</h1>
      <div>
        <!--        <span
        >{{ moment().format("YYYY.MM.DD(dd) HH:mm") }} {{ $t("data-today") }}</span
        >-->
<!--        <button class="medium" @click="handleRefresh">{{ $t("btn-refresh") }}</button>-->
        <!--        <button class="small margin6"  style="background: white;" @click="handlePop">
                  {{ $t("btn-new-browser") }}
                </button>-->
      </div>
    </div>
    <div class="contents allVod">
      <div class="box one filter">
        <div class="flex">
          <p class="bold">{{ $t("search-keyword-title") }}</p>
          <select v-model="keywordType">
            <option value="all">{{ $t("search-keyword-all") }}</option>
            <!-- <option value="">VOD ID</option> -->
            <option value="title">{{ $t("vod-data-title") }}</option>
            <option value="eventViewId">{{ $t("event-data-site-num") }}</option>
            <option value="eventName">{{ $t("event-data-site-name") }}</option>
            <option value="registerUserName">{{ $t("live-data-channel-operator") }}</option>
          </select>
          <input
              type="text"
              v-model="keyword"
              @keydown.enter="search()"
          />
          <div class="buttonWrap" style="display: flex; margin-top: 0;">
            <button class="point medium" @click="search()" style="width: 120px; margin-left: 20px;">
              {{ $t("btn-searching") }}
            </button>
            <button class="medium margin6" @click="$router.go()" style="width: 120px;">
              {{ $t("btn-reset") }}
            </button>
          </div>
        </div>
        <div class="flexC">
          <p class="bold">{{ $t("search-regdate-title") }}</p>
          <Datepicker
              v-model="startYmd"
              :language="ko"
              :format="customFormatter"
              placeholder="YYYY-MM-DD"
          ></Datepicker>
          <span>~</span>
          <Datepicker
              v-model="endYmd"
              :language="ko"
              :format="customFormatter"
              placeholder="YYYY-MM-DD"
          ></Datepicker>
        </div>
      </div>
      <div class="box one" >
        <h2 class="bold" style="display: inline-block">
          {{ $t("all-vod-list-replay-title") }}
        </h2>
        <p style="display: inline-block; margin-left: 30px;">
          {{ $t("data-total") }} {{ total }} {{$t("data-default-ea")}}{{$t("data-vod")}}
        </p>
        <div class="flexB">
          <div class="video-list" style="position: relative; border: 1px solid #d3d3d3;">
            <videoPlayer ref="video" :src="viewSrc" @onTimeUpdate="onTimeUpdate" :playerId="'isplay'" :isUser="userType == 'User'" :contentsId="contentsId" @contents-delete="onContentsDelete" @isCanPlay="playerLoadData"></videoPlayer>
          </div>
          <div class="mapWrap">
            <div class="map" ref= "map_div" >
              <Map style="width: 100%; height: 100%; position: absolute; border: 1px solid #d3d3d3;" ref="map" :isLeftButtonPosition="false" :loadMapName="loadMap" @play-marker-lng-lat="playMarkerLngLat" @map-move-center="onMapCenter"/>
              <!--<div v-if="locationSize == 0" style="z-index: 4; margin-top: 25%; font-size: 2em; right: 25%; left: 25%; line-height: 20; position: absolute; color: white; text-align: center; background-color: rgba(30, 30, 30, 0.8);">
                {{$t("popup-location-not-found")}}
              </div>-->
              <span v-if="locationSize == 0" style="position:absolute; z-index:4; top: 50%; left: 50%; transform: translate(-50%, -50%); height:auto; font-size: 2rem; font-weight: bold; color: white; background: rgba(0,0,0,0.8); padding: 2px 8px; ">
                  {{$t("popup-location-not-found")}}
              </span>
            </div>
            <div class="mapInfo" >
              <div class="bottom" v-if="viewSrc">

                <div class="infoData">
                  <div style="width: 100%; height: 22px; padding: 0 10px; ">
                    <p style="line-height: 22px;">
                      <span class="bold" style="color: #ff5400">No.{{ vodObj.eventViewId != null ? vodObj.eventViewId : vodObj.eventId }}</span>
                      <span class="bold" style="margin-left: 30px;">{{ vodObj.eventName }}</span>
                    </p>
                  </div>
                  <span style="margin-right: 0;">
                    <div class="vodInfo flexL" style="flex-direction: column;">
                      <div class="flexL" style="margin: 2px 0; padding-left: 10px; background: #F5F5F5; border-radius: 4px; height: auto; line-height: 24px;">
                        <div class="flex">
                          <div class="flex" style="margin-right: 10px;">
                            <span class="vodTitle" style="width: 60px;">{{$t("device-data-device-name")}}</span>
                            <span class="bold small vodData" style="font-size: 1.4rem;" v-if="vodObj.deviceType !== 'Mobile'">{{ vodObj.deviceName == null || vodObj.deviceName == undefined ? "휴대폰" : vodObj.deviceName }}</span>
                          </div>
                          <div class="flex" style="padding-right: 10px;"> <!--:style="isdrModelShow? 'visibility: visible':'display:none'">-->
                            <span class="vodTitle" style="width: 36px;">{{$t("device-data-model-name")}}</span>
                            <span class="bold small vodData textLimit" style="font-size: 1.4rem;"
                            :style="isdrModelShow? 'max-width:360px':'max-width:160px'" v-if="vodObj.deviceType !== 'Mobile'">{{ vodObj.deviceModelName == null ? "휴대폰" : vodObj.deviceModelName }}</span>
                    </div>
                  </div>
                  <div class="flex">
                    <div class="droneData flex" style="justify-content: flex-start !important;">
                      <span class="vodTitle">{{$t("device-data-manager-name")}}</span>
                      <span class="vodData">{{ vodObj.registerUserName }}</span>
                    </div>
                    <div class="droneData flex" style="justify-content: flex-start !important;">
                      <span class="vodTitle">{{$t("device-data-manager-dept-name")}}</span>
                      <span class="vodData">{{ vodObj.registerUserDeptName }}</span>
                    </div>
                  </div>
                </div>

              <div class="flex droneVal" style="width:100%; margin: 2px 0; padding-left: 10px;">
                <div class="droneData flex" style="justify-content: flex-start !important;">
                  <span class="DataName bold">고도</span>
                  <span class="vodData">{{ doneData.height == null ? 'N/A' : `${doneData.height.toFixed(0)} m` }}</span>
                </div>
                <div class="droneData flex" style="justify-content: flex-start !important;">
                  <span class="DataName bold">수평</span>
                  <span class="vodData">{{doneData.hspeed == null ? 'N/A' :  `${doneData.hspeed.toFixed(0)} m/s`}}</span>
                </div>
                <div class="droneData flex" style="justify-content: flex-start !important;">
                  <span class="DataName bold">수직</span>
                  <span class="vodData">{{ doneData.vspeed == null ? 'N/A' : `${doneData.vspeed.toFixed(0)} m/s` }}</span>
                </div>
                <div class="droneData flex" style="justify-content: flex-start !important;">
                  <span class="DataName bold">거리</span>
                  <span class="vodData">{{ doneData.distance == null ? 'N/A' : `${doneData.distance.toFixed(0)} m` }}</span>
                </div>
                <div class="droneData flex" style="display: none;">
                <span class="bold DataName">Bat</span>
                <span class="vodData">{{doneData.battery == null ? 'N/A' : `${doneData.battery.toFixed(0)}%`}}</span>
              </div>
                <div class="droneData flex" style="justify-content: flex-start !important;">
                  <span class="DataName bold">위도</span>
                  <span class="vodData" style="margin-right: 0; width: 90px; text-align: end;">{{lat == null ? 'N/A' : `${lat.toFixed(9)}`}}</span>
                </div>
                <div class="droneData flex" style="justify-content: flex-start !important;">
                  <span class="DataName bold">경도</span>
                  <span class="vodData" style="margin-right: 0; width: 90px; text-align: end;">{{lng == null ? 'N/A' : `${lng.toFixed(9)}`}}</span>
                </div>
              </div>
            </div>
              <div class="flexB" style="width: 100%; padding-left: 10px; margin-bottom: 0;">
                <div class="flexB" style="width: inherit; max-width: 510px; justify-content: space-between !important; margin-bottom: 0; line-height: 28px;" >
                    <span class="vodData grayW">{{ vodObj.title? vodObj.title : 'N/A' }}</span>
                    <span class="small vodData" style="margin-right: 0;">{{ moment(vodObj.registerDate).format("YYYY.MM.DD HH:mm") }}</span>
                </div>
                 <div class="btnWrap" style="width: auto; flex: auto; padding-left: 10px; text-align: end; ">
                  <button class="point" @click="playerOpen" style="padding: 0px; width: 132px; height: 28px;">
                    {{ $t("btn-vod-new-browser") }}
                  </button>
                </div>
              </div>
          </span>
          </div>
        </div>
      </div>
    </div>
  </div>
      </div>
      <div class="box one vodlist">
        <!--        <h2 class="bold">{{ $t("vod-list-title") }}</h2>-->
        <VodList ref="vodList" :vodUrl="vodUrl"  @viewVideo="viewVideo" @contents-total-size="contentsTotalSize" />
      </div>
    </div>
  </div>
</template>
<style>
.vodTitle{
  color: #808080;
  font-size: 1.2rem;
}
.vodData{
  color: #000;
  font-size: 1.2rem;
}
.DataName{
  color: #6ebeff;
  font-size: 1.2rem;
}
.droneData{
  margin-left: 10px;
}
.droneData:nth-child(1){
  margin-left: 0;
}
</style>
<script>
import moment from "moment";
import videoPlayer from "@/components/video/videoPlayer";
import Map from "@/components/map/Map";
import MapLineData from "@/components/map/data/MapLineData";
import MapDataType from "@/components/map/enum/MapDataType";
import MapMarkerData from "@/components/map/data/MapMarkerData";

import "moment/locale/ko";
import { mapState } from "vuex";
import VodList from "@/views/dashboard/DashContentsList";
import { fetchVodUrlList } from "@/api/server";
import { fetchVodDetail } from "@/api/contents";
import { fetchLocation } from "@/api/location";
import { getDeviceTypeName } from "@/api/channel";
import { fetchEvent} from "@/api/event";
import { fetchPointList, getPointTypeName } from "@/api/point";
import {playerPopOpen} from "@/util/popup";
import {getDefaultMapZoomLevel} from "@/util/common";
import {ko} from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";

export default {
  components: { VodList, videoPlayer, Map, Datepicker },
  name: "Main",
  data() {
    return {
      index: 0,
      moment: moment,
      vodUrl: "",
      middleUrl: "",
      SDList: [],
      /*검색 데이터*/
      ko: ko,
      startYmd: null,
      endYmd: null,
      currentPage: 0,
      size: 10,
      keywordType: "all",
      keyword: "",
      total: 0,
      vodList: [],
      viewSrc : null,
      isPlayStatus: false,
      contentsId: null,
      vodObj: {},
      lat : null,
      lng : null,
      eventInfo: [],
      locationSize : 0,
      loadMap : "kakao",
      accountId: localStorage.getItem("accountId"),
      location : [],

      windowWidth: null,
      isdrModelShow:null,
      drNameLeng:null,

      doneData : {
        distance : null,
        hspeed : null,
        vspeed : null,
        height : null,
        battery : null,
      },

    };
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 12, sub: 0 });
   this.windowWidth = window.innerWidth;
   /*
    if(this.vodObj.deviceName == null || this.vodObj.deviceName == undefined){
      this.drNameLeng = 0;
    }else{
      this.drNameLeng = this.vodObj.deviceName.length;
    }*/
    window.addEventListener('resize', this.handleResize);
  },
  mounted() {
    this.getVodList();
    this.initMapData(this.currentLang );

    // 1. ResizeObserver 객체 만들기
    if(this.$refs.map != undefined && this.$refs.map_div != undefined) {
      const observer = new ResizeObserver(entries => {
        for (let entry of entries) {
          const {width, height} = entry.contentRect;
          width;
          height;
          this.$refs.map.setLayout();
        }
      });

      // 2. 감지할 요소 추가하기
      observer.observe(this.$refs.map_div);
    }
  },
  watch : {
    currentLang(value){
      this.initMapData(value);
    },
    windowWidth(){
      this.windowWidth = window.innerWidth;
      this.handleResize()
    }
  },
  computed: {
    ...mapState({
      gnbVisible: state => state.store.gnbVisible,
      currentLang: state => state.store.currentLang,
      userType: state => state.store.userType,
    }),
  },
  methods: {
    onContentsDelete(){
      this.$refs.vodList.getContentsList();
    },
    handleResize(){
      this.windowWidth = window.innerWidth;
      if(this.windowWidth < 1650){
        this.isdrModelShow = false;
      }else{
        this.isdrModelShow = true;
      }
    },
    playerLoadData(id, isLoad) {
      this.isPlayStatus = isLoad;
    },
    initDoneData(){
      this.doneData.distance = null;
      this.doneData.hspeed = null;
      this.doneData.vspeed = null;
      this.doneData.height = null;
      this.doneData.battery = null;
    },
    playMarkerLngLat(lng, lat, index) {
      this.$refs.map.onSetLngLat(lng,lat);
      if(this.location.length > 0 ) {
        let locationData = this.location[index];
        this.doneData.distance = locationData.distance;
        this.doneData.hspeed = locationData.hspeed;
        this.doneData.vspeed = locationData.vspeed;
        this.doneData.height = locationData.height;
        this.doneData.battery = locationData.battery;
        this.lng = lng;
        this.lat = lat;
      }
    },
    initMapData(value){
      if(value == 'en') {
        this.loadMap = 'google'
      } else {
        this.loadMap = 'kakao'
      }

      this.setMapSetting();

      this.$nextTick(function(){
        this.$refs.map.onSetZoomLevel(getDefaultMapZoomLevel(this.currentLang));
        if(this.vodObj.eventId != undefined) {
          this.getEvent();
          this.getEventPoint();
        }

        this.getLocation();

      })
    },
    createMapMarker(mapDataType, id, name, location, isMain) {
      let mapMarkerData = new MapMarkerData();
      mapMarkerData.id = id;
      mapMarkerData.lng = location.lng;
      mapMarkerData.lat = location.lat;
      mapMarkerData.isMain = isMain;
      mapMarkerData.name = name;
      if (this.$refs.map.onIsMarker(mapDataType, id)) {
        this.$refs.map.onModifyMarkerAt(mapDataType, mapMarkerData);
      } else {
        this.$refs.map.onCreateMarker(mapDataType, mapMarkerData);
      }
    },
    onMapCenter() {
      if (this.eventInfo == null) {
        return;
      }
      this.$refs.map.onSetZoomLevel(getDefaultMapZoomLevel(this.currentLang));
      if (this.eventInfo.lng != null && this.eventInfo.lng != "" && this.eventInfo.lat != null && this.eventInfo.lat != "") {
        this.lng = this.eventInfo.lng;
        this.lat = this.eventInfo.lat;

        this.$refs.map.onCreateMapMarker(this.eventInfo.eventId, this.eventInfo.lng, this.eventInfo.lat, MapDataType.ID_SITE);

        this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
      } else {
        this.$refs.map.onAddressToMoveCenter(this.eventInfo.address);
      }
    },
    setMapSetting(){
      this.$refs.map.onShowCenterPoint();
      this.$refs.map.onShowButtonTypeChange();
      //this.$refs.map.onShowLngLat();
    },
    async getEventPoint() {
      let params = {
        eventId : this.vodObj.eventId,
        serverTime : null,
      };
      this.$refs.map.onClearMapData();
      this.$refs.map.onSetLngLat(this.lng,this.lat);
      await fetchPointList( params).then((res) => {
        if (res.data.result == 0) {
          this.pointServerTime = res.data.data.serverTime
          this.pointCount = res.data.data.total
          this.pointList = res.data.data.content
          this.pointList.forEach((element) => {
            element.selectId = element.index;
            element.selectType = "Point";
            element.isOwner = false;
            let pointMapMarker = new MapMarkerData();
            element.name = this.$t(getPointTypeName(element.type));
            pointMapMarker.setPointData(element);
            this.$refs.map.onCreateMarker(MapDataType.ID_POINT, pointMapMarker);
          })
        }
      });
    },
    onTimeUpdate(time) {
      this.$refs.map.onPlayUpdatePosition(time);
    },
    contentsTotalSize(size){
      this.total = size;
    },
    getEvent(){
      if(this.vodObj != null) {
        fetchEvent(this.vodObj.eventId).then((res) => {
          //this.lat = res.data.data.lat
          //this.lng = res.data.data.lng
          if (res.data.result == 0) {
            this.eventInfo = res.data.data;
            this.$refs.map.onSetLngLat(res.data.data.lat, res.data.data.lng);
            this.$refs.map.onCreateMapMarker(this.eventInfo.eventId, this.eventInfo.lng, this.eventInfo.lat, MapDataType.ID_SITE);
          }
        });
      }
    },
    getLocation(){
      this.initDoneData();
      if(this.contentsId != null) {
        let params = {
          contentsId: this.contentsId,
        };
        fetchLocation(params).then((res) => {
          if (res.data.result == 0) {
            this.locationSize = res.data.data.content.length;
            if(res.data.data.content.length == 0 && this.lng != null &&  this.lat != null) {
              this.$refs.map.onLngLatToMoveCenter(this.lng, this.lat)
              return;
            } else if(res.data.data.content.length == 0) {
              this.$refs.map.onAddressToMoveCenter(this.contentObj.eventAddress);
              return;
            }
            this.location = res.data.data.content.sort(function(a, b) {
              return a.index - b.index;
            })
            var first = res.data.data.content[0];
            this.lng = first.lng;
            this.lat = first.lat;
            this.$refs.map.onLngLatToMoveCenter(first.lng, first.lat)
            var mapLineData = new MapLineData();
            mapLineData.id = this.contentsId;
            mapLineData.locationGroup = res.data.data.content;
            this.$refs.map.onPlayCreateData(mapLineData);
          }
        });
      }
    },
    async viewVideo(data) {
      this.viewSrc = data.src;
      this.isPlayStatus = false;
      this.contentsId = data.contentsId;

      fetchVodDetail(this.contentsId).then((res) => {
        this.vodObj = res.data.data;
        this.initMapData(this.currentLang);
      });
    },
    getDeviceTypeName(type){
      return getDeviceTypeName(type);
    },
    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },
    search() {
      if (this.startYmd != null) {
        if (this.startYmd > this.endYmd || !this.endYmd || !this.startYmd) {
          return alert(this.$t("alert-message-check-regdate"));
        }
      }
      this.currentPage = 0;
      this.getVodList();
      this.$refs.vodList.currentPage = this.currentPage;
      this.$refs.vodList.keywordType = this.keywordType;
      this.$refs.vodList.keyword = this.keyword;
      this.$refs.vodList.startYmd = this.startYmd;
      this.$refs.vodList.endYmd = this.endYmd;
      this.$refs.vodList.getContentsList();
    },
    getVodList() {
      fetchVodUrlList().then((res) => {
        this.vodUrl = res.data.data.properties.vodStreamUrl + "/" + res.data.data.properties.application;
      });
    },
    handlePop() {
      this.index = this.index + 1;
      window.open(
          `/${this.accountId}/dashboard/allVodList?new=true`,
          `newWindow${this.index}`,
          "left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no,status=no"
      );
    },
    handleRefresh() {
      this.$router.go();
    },
    playerOpen() {
      playerPopOpen("vod",this.contentsId);
    },
  },
};
</script>
