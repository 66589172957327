<template>
  <div class="vodList">
    <div class="vodWrap flexL">
      <div class="all-video-list" :class="{'active': selectId == data.contentsId}" v-for="(data, i) in vodList" :key="i" @click="getVod(data.contentsId, data.eventId)">
        <button class="videoBtn"></button>
        <div class="thumBox">
          <img :src="baseUrl + data.thumbnailUri" />
          <div class="duration">
            <span class="duration">{{ changeDuration(data.duration) }}</span>
          </div>
        </div>
        <span class="textLimit" style="width: 100%;">
          <span class="bold small ghgh">No.{{ data.eventViewId != null ? data.eventViewId : data.eventId }}</span>
          <span class="bold small">{{ data.eventTitle }}</span>
        </span>
        <span class="textLimit" style="width:100%; display: flex; ">
          <span class="bold small" style="max-width: 80px;"> {{ data.deviceType != 'Mobile' ? data.deviceName : $t(getDeviceTypeName(data.deviceType)) }}</span>
          <span class="bold small"> {{ data.registerUserName }}</span>
          <span class="bold small"> {{ data.registerDepartmentName }}</span>
        </span>
        <p class="bold flexL" style="margin-top: 0;">
          <span class="TitleVod" style="font-size: 1.2rem; font-weight: normal;">{{ data.title }}</span>
          <span style="font-size: 1.2rem; font-weight: normal;">{{moment(data.registerDate).format("YYYY.MM.DD HH:mm")}}</span>
        </p>
      </div>
    </div>
    <div class="pagination">
      <el-pagination
        small
        layout="prev, pager, next"
        :page-size="size"
        @current-change="handleCurrentChange"
        :total="total"
        :current-page="currentPage + 1"
      >
      </el-pagination>
    </div>
  </div>
</template>
<style>
div.duration {
  position: absolute;
  width: 36px;
  height: 18px;
  right: 12px;
  bottom: 9px;
  padding: 1px 4px 2px 5px;
  background-color: rgba(30, 30, 30, 0.5);
}
.textLimit>.small{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
span.duration {
  width: 27px;
  height: 15px;
  font-family: Noto Sans KR, Noto;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}
.TitleVod{
  display: block;
  width: 60%;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}
.vodName{
  font-size: 1.2rem;
  font-weight: normal;
  color: #808080;
}
</style>
<script>
import moment from "moment";
import { baseUrl } from "@/config/env";
import { fetchVodSrc } from "@/api/file";
import { fetchVodList } from "@/api/contents";
import { getDeviceTypeName } from "@/api/channel";

export default {
  props: {
    eventId: {
      type: String,
      default: "",
    },
    vodUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      index: 0,
      moment: moment,
      baseUrl: baseUrl,
      currentPage: 0,
      vodList: [],
      total: 0,
      size: 12,
      selectId : null,
      scr: "",
      keywordType: null,
      keyword: null,
      startYmd: null,
      endYmd: null
    };
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 12, sub: 0 });
  },
  mounted() {
    this.getContentsList();
  },
  methods: {
    changeDuration(seconds) {
      if(seconds == null) {
        return "00:00";
      }
      var min = parseInt((seconds%3600)/60) < 10 ? '0'+ parseInt((seconds%3600)/60) : parseInt((seconds%3600)/60);
      var sec = seconds % 60 < 10 ? '0'+seconds % 60 : seconds % 60;
      return min+":" + sec;
    },
    async getContentsList() {
      let params = {
        pageNumber: this.currentPage,
        pageSize: this.size,
        eventId: this.eventId,
        statuses: "Close,Open",
        deviceType : "Drone,Mobile",
        keywordType: this.keywordType,
        keyword: this.keyword
      };
      if (!this.eventId) {
        delete params.eventId;
      }
      if(this.startYmd != null && this.endYmd != null) {
        params["registeredDateRange.from"] = moment(this.startYmd).format("YYYY-MM-DD");
        params["registeredDateRange.to"] = moment(this.endYmd).format("YYYY-MM-DD");
      }
      await fetchVodList(params).then((res) => {
        this.total = res.data.data.total;
        this.vodList = res.data.data.content;
        this.$emit("contents-total-size", this.total)
        this.getVod();
      });
    },
    getDeviceTypeName(type){
      return getDeviceTypeName(type);
    },
    getVod(contentsId = null, eventId = null) {
      if( this.vodList.length == 0 ) {
        return;
      }

      this.selectId = null;
      if (contentsId == null) {
        contentsId = this.vodList[0].contentsId;
      }

      this.selectId = contentsId;
      fetchVodSrc(contentsId).then((res) => {
        if (res.data.result == 0) {
          let data = {
            src: this.vodUrl + res.data.data.streamUri,
            eventId: eventId,
            contentsId: contentsId,
          };
          if (res.data.data.streamUri == undefined) {
            data.src = "";
          }
          if (!eventId) {
            delete data.eventId;
          }
          this.$emit("viewVideo", data);
        }
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val - 1;
      this.getContentsList();
    },
  },
};
</script>
